import React from "react";

import { Button } from "@mui/material";

import { ErrorMessage, LOGIN_SIGNUP_STATES } from "./constants";

import "./login.css";

function Register({ register, formState: { errors }, handleSubmit, setState }) {
  const onSubmit = (data) => {
    if (data) {
      setState(LOGIN_SIGNUP_STATES.RegisterConfirmation);
    }
  };
  return (
    <div className="loginFormMainWrap">
      <div className="inputWrap">
        <div className="input-login-form-wrap">
          <div className="input-container-login">
            <label htmlFor="email" className="input-label">
              Email
            </label>
            <input
              type="email"
              id="email"
              className="custom-input"
              placeholder="user@gmail.com"
              {...register("email")}
            />
            <ErrorMessage error={errors?.email} />
          </div>
          <div className="input-container-login position-relative">
            <label htmlFor="password" className="input-label">
              Password
            </label>
            <input
              type="password"
              id="password"
              className="custom-input"
              placeholder="********"
              {...register("password")}
            />
            <ErrorMessage error={errors?.password} />
          </div>
          <div className="input-container-login position-relative">
            <label htmlFor="password" className="input-label">
              Confirm password
            </label>
            <input
              type="password"
              id="confirmPassword"
              className="custom-input"
              placeholder="********"
              {...register("confirmPassword")}
            />
            <ErrorMessage error={errors?.confirmPassword} />
          </div>
        </div>
        <div className="logInBtnWrap">
          <Button type="button" onClick={handleSubmit(onSubmit)}>
            Next step
          </Button>
        </div>

        <div className="divider-container register-divider">
          <span className="divider-text">Or register with social media</span>
        </div>
      </div>
      <div className="loginSocialBtnWrap">
        <Button>
          <img src="/images/loginMeta.png" alt="" />
          Meta
        </Button>
        <Button>
          <img src="/images/loginGoogle.png" alt="" />
          Google
        </Button>
      </div>
    </div>
  );
}

export default Register;
