import axiosClient from "../axios";

export default class transactionsService {
  static async getTransactions(params) {
    try {
      const { data } = await axiosClient.get("/user/transactions", {
        params,
      });
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async getMyBets(params) {
    try {
      const { data } = await axiosClient.get("/Gsoft/getAllBets", {
        params,
      });
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async getUsersAllBets(params) {
    try {
      const { data } = await axiosClient.get("/transaction/user-bet-history", {
        params,
      });
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async getUsersAllBonusHist(params) {
    try {
      const { data } = await axiosClient.get(
        "/transaction/user-bonus-history",
        {
          params,
        }
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async getUsersAllTransactionHist(params) {
    try {
      const { data } = await axiosClient.get(
        "/transaction/user-transaction-history",
        {
          params,
        }
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async cancelUserTransaction(payload) {
    try {
      const { data } = await axiosClient.post(
        "/transaction/cancel-transaction",
        payload
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
  static async xCoinTransactionDetails(payload) {
    try {
      const { data } = await axiosClient.get(
        "/transaction/xcoin-transaction-details",
        {
          params: payload,
        }
      );
      return data;
    } catch (error) {
      if (error.data) {
        return { error: error.data };
      }
      return {
        error,
      };
    }
  }
}
