/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useRef, useState } from "react";

import { Button } from "@mui/material";

import { LOGIN_SIGNUP_STATES } from "./constants";

export default function TermsAndCondition({ setState }) {
  const ref = useRef(null);
  const [isNextDisabled, setIsNextDisabled] = useState(true);
  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        const entry = entries[0];
        if (entry.isIntersecting) {
          setIsNextDisabled(false);
        }
      },
      { threshold: 0.5 }
    );
    if (ref.current) {
      console.log("observer integrated");
      observer.observe(ref.current);
    }
    return () => {
      if (ref.current) {
        observer.unobserve(ref.current);
      }
    };
  }, []);
  return (
    <>
      <div className="agreeContentWrap">
        <p>Please, read and acknowledge our policies </p>
        <div className="agreeContentScrollWrap">
          <span>
            We deeply comprehend the significance of privacy and are fully
            dedicated to safeguarding your personal information. Below, you will
            find an outline of the privacy policies and agreements that we
            strictly adhere to:
          </span>
          <span className="marginTop15">
            1. Under no circumstances will we engage in the sale or sharing of
            your email address with any third parties.
          </span>
          <span>
            2. Rest assured that your information is securely stored and can
            only be accessed by authorized personnel.
          </span>
          <br />
          <span>
            3. Our commitment to safeguarding your data involves adhering to
            industry-leading best practices to maintain the utmost
            confidentiality.
          </span>
          <span className="marginTop15">
            1. Under no circumstances will we engage in the sale or sharing of
            your email address with any third parties.
          </span>
          <span>
            2. Rest assured that your information is securely stored and can
            only be accessed by authorized personnel.
          </span>
          <br />
          <span>
            3. Our commitment to safeguarding your data involves adhering to
            industry-leading best practices to maintain the utmost
            confidentiality.
          </span>
          <div ref={ref} style={{ height: "10px" }}></div>
        </div>
      </div>
      <div className="loginSocialBtnWrap">
        <Button onClick={() => setState("")}>Back</Button>
        <Button
          disabled={isNextDisabled}
          className="agreeBtn"
          onClick={() => setState(LOGIN_SIGNUP_STATES.RegisterNameSelection)}
        >
          I agree
        </Button>
      </div>
    </>
  );
}
