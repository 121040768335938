import React, { useState } from "react";

import { StepsEnum } from "./constants";
import SelectPaymentAmount from "./selectPaymentAmount";
import SelectPaymentMethod from "./selectPaymentMethod";
import CashAppPayment from "./cashAppPayment";
import PaymentSuccess from "./paymentSuccess";
import BitCoinPayment from "./bitCoinPayment";
import PaymentProcess from "./paymentProcess";
import SelectRedemptionAmount from "./selectRedemptionAmount";
import AchDirectRedeem from "./achDirectRedeem";
import SelectRedemptionMethod from "./selectRedemptionMethod";
import DebitCardPayment from "./debitCardPayment";
import AchDirect from "./achDirect";

export default function SelectedStateView(props) {
  const {
    step,
    activeCard,
    setActiveCard,
    setStep,
    coinPackages,
    loader,
    handleCardPayment,
    isPending,
    setLoader,
    transactionStatus,
    setTransactionStatus,
  } = props;
  const [withdrawAmount, setWithdrawAmount] = useState();

  switch (step) {
    case StepsEnum.Amount:
      return (
        <SelectPaymentAmount
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          setStep={setStep}
          coinPackages={coinPackages}
          loader={loader}
          isPending={isPending}
        />
      );
    case StepsEnum.Method:
      return (
        <SelectPaymentMethod
          setStep={setStep}
          loader={loader}
          handleCardPayment={handleCardPayment}
          activeCard={activeCard}
        />
      );
    case StepsEnum.BitCoin:
      return <BitCoinPayment setStep={setStep} activeCard={activeCard} />;

    case StepsEnum.CashApp:
      return (
        <CashAppPayment
          activeCard={activeCard}
          setStep={setStep}
          setTransactionStatus={setTransactionStatus}
        />
      );
    case StepsEnum.PayMobil:
      return <>PayMobil</>;
    case StepsEnum.Card:
      return <>Card</>;
    case StepsEnum.Success:
      return <PaymentSuccess transactionStatus={transactionStatus} />;
    case StepsEnum.RedeemAmount:
      return (
        <SelectRedemptionAmount
          setStep={setStep}
          setLoader={setLoader}
          loader={loader}
          withdrawAmount={withdrawAmount}
          setWithdrawAmount={setWithdrawAmount}
        />
      );
    case StepsEnum.Pending:
      return <PaymentProcess />;
    case StepsEnum.AchDirect:
      return <AchDirectRedeem {...props} />;
    case StepsEnum.RedeemMethod:
      return <SelectRedemptionMethod setStep={setStep} />;
    case StepsEnum.DebitCardPayment:
      return <DebitCardPayment setStep={setStep} {...props} />;
    case StepsEnum.AchDirectNew:
      return (
        <AchDirect
          {...props}
          withdrawAmount={withdrawAmount}
          setTransactionStatus={setTransactionStatus}
        />
      );
    default:
      return (
        <SelectPaymentAmount
          activeCard={activeCard}
          setActiveCard={setActiveCard}
          setStep={setStep}
        />
      );
  }
}
