import React, { useEffect, useRef, useState } from "react";

import { isMobile } from "react-device-detect";
import {
  Grid,
  Tooltip,
  Button,
  Typography,
  DialogContent,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams, Link } from "react-router-dom";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";
import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";

import touchHand from "../../../../src/images/hand-pointer.svg";
import useStyles from "./IframeGame.styles";
import Game from "../Game";
import Modal from "../../../hoc/modal/Modal";
import { getGameLinkStart } from "../../../redux-saga/redux/casino/lobby";
import LiveTable from "../../../components/LiveTable";
import { formatCategoryName } from "../../../utils/helper";
import { getGameDetailStart } from "../../../redux-saga/redux/casino/lobby/games";
import backIcon from "../../../../src/images/back.png";
import config from "../../../config";

const IframeGame = () => {
  const classes = useStyles();
  const params = useParams();
  const { gameId } = params;
  const iframeRef = useRef();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { gameLink, loading } = useSelector((state) => state.casinoLobby);
  const { gameDetail, relatedGames } = useSelector((state) => state.favorites);
  const [isFullScreenModal, setIsFullScreenModal] = useState(false);
  const [shouldAddClass, setShouldAddClass] = useState(false);

  useEffect(() => {
    if (isMobile) {
      setIsFullScreenModal(false);
    }
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0);
    dispatch(
      getGameLinkStart({
        selectedWallet: localStorage.getItem("selectedWallet") || "coin",
        gameId: parseInt(gameId),
        device_type: "desktop",
        homeURL: config?.clientUrl,
      })
    );
    dispatch(getGameDetailStart({ gameId }));
  }, [dispatch, gameId]);

  const RequestFullScreen = () => {
    iframeRef.current.requestFullscreen();
    setIsFullScreenModal(false);
  };
  const GotoLobby = () => {
    setTimeout(function () {
      window.location.reload(true);
    }, 500);
    window.history.back();
  };
  const LeftButton = () => {
    const { scrollPrev } = React.useContext(VisibilityContext);
    return (
      <Button
        className={`${classes.leftArrow} leftArrowIcon`}
        onClick={() => scrollPrev()}
      >
        <KeyboardArrowLeftTwoToneIcon />
      </Button>
    );
  };

  const RightButton = () => {
    const { scrollNext } = React.useContext(VisibilityContext);
    return (
      <Button
        className={`${classes.rightArrow} rightArrowIcon`}
        onClick={() => scrollNext()}
      >
        <NavigateNextTwoToneIcon />
      </Button>
    );
  };

  const { darkMode } = useSelector((state) => state.themeSettings);
  useEffect(() => {
    // Apply styles when the component mounts
    document.body.classList.add("gameBody");

    // Clean up and remove styles when the component unmounts
    return () => {
      document.body.classList.remove("gameBody");
    };
  }, []);

  useEffect(() => {
    const handleScroll = () => {
      const scrollPosition = window.scrollY;
      // Check if the scroll position is greater than or equal to 50px
      setShouldAddClass(scrollPosition >= 30);
    };

    // Add scroll event listener
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <>
      {loading ? (
        <Grid className={classes.iframeCirculerLoader} textAlign="center">
          <div className={"load"} />
        </Grid>
      ) : (
        <div className={`${classes.iframeBg} iframeAfterHide`}>
          <Grid className={classes.actionButton}>
            <Grid className={classes.iframeGameName}>
              <p>{gameDetail?.name || "Game Name"}</p>
            </Grid>

            <Grid
              className={`${classes.iframeIcon} ${classes.desktopiframeIcon}`}
            >
              <Grid className={`${classes.icons} demoClass`}>
                <Tooltip
                  title={`Provider : ${gameDetail?.MasterCasinoProvider?.name}  |
              Category : ${gameDetail?.MasterGameSubCategory?.name}
              `}
                  arrow
                >
                  <img src="/images/iframe-icon1.svg" alt="iframe-icon-1" />
                </Tooltip>
              </Grid>

              <Grid className={classes.icons} onClick={RequestFullScreen}>
                <img src="/images/iframe-icon-2.svg" alt="iframe-icon-2" />
              </Grid>

              <Grid className={classes.icons}>
                <img src="/images/iframe-icon-3.svg" alt="iframe-icon-3" />
              </Grid>

              <Grid className={classes.icons} onClick={GotoLobby}>
                <img src="/images/iframe-icon-4.svg" alt="iframe-icon-4" />
              </Grid>
            </Grid>
          </Grid>

          <div
            className={`orientation-mobile horizontal ${
              shouldAddClass ? "h-vertical" : ""
            }`}
          >
            <img src={touchHand} alt="hand" />
          </div>

          <div className="home-icon">
            <Link onClick={() => navigate(-1)}>
              <img src={backIcon} alt="backIcon" />
            </Link>
          </div>

          <iframe
            className="mbIframe mbiframeMobile"
            src={gameLink}
            title="Game Frame"
            width="100%"
            // height="741px"
            ref={iframeRef}
          />
        </div>
      )}

      <Grid className={classes.wrap}>
        {loading ? (
          <Grid textAlign="center"></Grid>
        ) : (
          relatedGames?.length > 0 && (
            <Grid key={"related games"} className={classes.contentWrap}>
              <Grid className={classes.innerHeading}>
                <Grid className={classes.innerHeadingLeft}>
                  {darkMode ? (
                    <img src="/images/star-icon.svg" alt="Star Icon" />
                  ) : (
                    <img src="/images/light-star-icon.svg" alt="Star Icon" />
                  )}
                  <Typography variant="h6" gutterBottom>
                    Related Games
                  </Typography>
                </Grid>

                {relatedGames?.length > 6 && (
                  <Link
                    to={`/view-all/false/${relatedGames?.[0]?.MasterGameSubCategory?.masterGameSubCategoryId}/${relatedGames?.[0]?.MasterGameSubCategory?.name}`}
                    className={classes.btnSecondary}
                  >
                    See all
                  </Link>
                )}
              </Grid>
              <Grid className={classes.filterWrap}>
                <ScrollMenu
                  LeftArrow={relatedGames?.length > 6 && LeftButton}
                  RightArrow={relatedGames?.length > 6 && RightButton}
                  wrapperClassName="horizontalScrollWrapper"
                >
                  {relatedGames?.map((game, index) => (
                    <Game
                      providerName={game?.MasterCasinoProvider?.name || null}
                      key={`${game.masterCasinoGameId}${index}`}
                      thumbnailUrl={game.thumbnailUrl}
                      name={game.name}
                      gameId={game.masterCasinoGameId}
                      filterBy={"Category"}
                      filterName={formatCategoryName(
                        relatedGames?.[0]?.masterGameSubCategory?.name
                      )}
                      searchGame={false}
                      isFavorite={!!game.isFavorite || true}
                      providerId={game?.masterCasinoProviderId}
                    />
                  ))}
                </ScrollMenu>
              </Grid>
            </Grid>
          )
        )}
      </Grid>

      <LiveTable />

      <Modal
        isShowing={isFullScreenModal}
        className={classes.fullScreenModal}
        content={
          <DialogContent className={classes.fullScreenModalContent}>
            <p>Do you want to play the game in full screen?</p>
            <div className={classes.buttonContainer}>
              <button
                className={classes.noButton}
                onClick={() => setIsFullScreenModal(false)}
              >
                No
              </button>
              <button className={classes.yesButton} onClick={RequestFullScreen}>
                Yes
              </button>
            </div>
          </DialogContent>
        }
        hide={() => setIsFullScreenModal(false)}
      />
    </>
  );
};

export default IframeGame;
