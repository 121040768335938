/* eslint-disable react-hooks/exhaustive-deps */
import React, {
  useEffect,
  // useCallback,
  // useContext,
  useState,
  useTransition,
} from "react";

import {
  Grid,
  Typography,
  // CircularProgress,
  // Button,
} from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import { isMobile } from "react-device-detect";
// import KeyboardArrowLeftTwoToneIcon from "@mui/icons-material/KeyboardArrowLeftTwoTone";
// import NavigateNextTwoToneIcon from "@mui/icons-material/NavigateNextTwoTone";
import { ScrollMenu, VisibilityContext } from "react-horizontal-scrolling-menu";

import useStyles from "./Lobby.styles";
// import { carouselGameLimit } from "../../../utils/constants";
import // getLandingGames,
//updateLandingGames,
"../../../redux-saga/redux/casino/lobby";
import { formatCategoryName } from "../../../utils/helper";
import Game from "../Game";
import Provider from "../Provider";
// import LiveTable from "../../../components/LiveTable";
// import config from "../../../config";
import lobbyService from "../../../services/casino/lobby";
import { getLandingGamesSuccess } from "../../../redux-saga/redux/casino/lobby";
//import { updateLandingGamesInFav } from "../../../redux-saga/redux/casino/lobby/games";

const Games = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  console.log(VisibilityContext)
  // const { scrollNext, scrollPrev } = useContext(VisibilityContext);
  const {
    authentication: { userId, selectedWallet },
    themeSettings: { darkMode },
   
  } = useSelector((state) => state);
  const [gamesList, setGamesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isPending, startTransition] = useTransition();
  console.log(darkMode)
  useEffect(() => {
    setLoading(true);
    lobbyService
      .getLandingGames({
        limit: 20,
        isMobile: isMobile,
        isFeatured: true,
        coin: selectedWallet || "coin",
        loadMoreGames: null,
      })
      .then((res) => {
        if (res.status === 200) {
          dispatch(getLandingGamesSuccess({ data: res.data }));
          startTransition(() => {
            setGamesList(res.data);
          });
        }
      })
      .catch((err) => {
        console.error("[Error]", err);
      })
      .finally(() => {
        setLoading(false);
      });
  }, [isMobile, selectedWallet, userId]);

  //const { recentsGames } = useSelector((state) => state.favorites);

  // eslint-disable-next-line react-hooks/exhaustive-deps

  // const LeftButton = useCallback(() => {
  //   return (
  //     <Button
  //       className={`${classes.leftArrow} leftArrowIcon`}
  //       onClick={() => scrollPrev()}
  //     >
  //       <KeyboardArrowLeftTwoToneIcon />
  //     </Button>
  //   );
  // }, [scrollPrev]);

  // const RightButton = useCallback(() => {
  //   return (
  //     <Button
  //       className={`${classes.rightArrow} rightArrowIcon`}
  //       onClick={() => scrollNext()}
  //     >
  //       <NavigateNextTwoToneIcon />
  //     </Button>
  //   );
  // }, [scrollNext]);
  // useEffect(() => {
  //   dispatch(
  //     getLandingGames({
  //       limit: 20,
  //       isMobile: isMobile,
  //       isFeatured: true,
  //       coin: selectedWallet || "coin",
  //     })
  //   );
  // }, [dispatch, userId, selectedWallet]);

  const getCatData = (gameData) => {
    const obj = {
      iconName: gameData?.["MasterGameSubCategory.icon_name"],
      masterGameSubCategoryId:
        gameData?.["MasterGameSubCategory.master_game_sub_category_id"],
    };
    return obj;
  };

  return (
    <>
      <Grid className={classes.wrap}>
        {loading || isPending ? (
          <Grid textAlign="center" className={classes.circulerLoader}>
            <div className={"load"} />
          </Grid>
        ) : (
          <>
            {gamesList && gamesList?.length > 0 ? (
              <>
                {Object.keys(gamesList[0]).map((category) => {
                  const categoryData =
                    gamesList[0][category].length > 0
                      ? getCatData(gamesList[0][category][0])
                      : null;
                  const games = gamesList[0][category];
                  return categoryData ? (
                    <Grid key={category} className={classes.contentWrap}>
                      <Grid className={classes.innerHeading}>
                        <Grid className={classes.innerHeadingLeft}>
                          {/* {darkMode ? (
                            <img
                              src={
                                category === "Hot Games"
                                  ? "https://api.cryptorolls.com/hotgame-star-icon.svg"
                                  : 
                                  category === "New Games"
                                  ? `${config?.baseUrl}/new.svg`
                                  : categoryData?.iconName
                                  ? categoryData.iconName
                                  : "/images/star-icon.svg"
                              }
                              alt="Star Icon"
                            />
                          ) : (
                            <img
                              src={
                                category === "Hot Games"
                                  ? "https://api.cryptorolls.com/hotgame-star-icon.svg"
                                  :

                                  category === "New Games"
                                  ? `${config?.baseUrl}/new.svg`
                                  : categoryData?.iconName
                                  ? categoryData.iconName
                                  : "/images/light-star-icon.svg"
                              }
                              alt="Star Icon"
                            />
                          )} */}
                          <Typography variant="h6" gutterBottom>
                            {formatCategoryName(category) || ""}
                          </Typography>
                        </Grid>
                        {games?.length > 6 && (
                          <Link
                            to={`${
                              category === "Hot Games"
                                ? `/view-all/false/20/${category}`
                                : category !== "New Games"
                                ? `/view-all/false/${categoryData.masterGameSubCategoryId}/${category}`
                                : "all-games"
                            }`}
                            className={classes.btnSecondary}
                          >
                            See all
                          </Link>
                        )}
                      </Grid>
                      <Grid
                        className={`${classes.filterWrap} ${classes.filterWrapSlider}`}
                      >
                        <ScrollMenu
                          // LeftArrow={
                          //   games?.length > carouselGameLimit && LeftButton
                          // }
                          // RightArrow={
                          //   games?.length > carouselGameLimit && RightButton
                          // }
                          wrapperClassName="horizontalScrollWrapper"
                        >
                          {games &&
                            games?.length > 0 &&
                            games?.map((game, index) => (
                              <Game
                                providerName={
                                  game?.MasterCasinoProvider?.name || null
                                }
                                key={`${game?.masterCasinoGameId}${index}`}
                                thumbnailUrl={game?.thumbnailUrl}
                                name={game?.name}
                                gameId={game?.masterCasinoGameId}
                                filterBy={"Category"}
                                filterName={formatCategoryName(category)}
                                searchGame={false}
                                isFavorite={!!game?.isFavorite}
                                providerId={game?.masterCasinoProviderId}
                              />
                            ))}
                        </ScrollMenu>
                      </Grid>
                    </Grid>
                  ) : null;
                })}
              </>
            ) : (
              <h1 className={classes.noGamesMsg}>No Games Found</h1>
            )}
          </>
        )}
      </Grid>

      <Grid>
        <Provider />
      </Grid>

      {/* <Grid>
        <LiveTable />
      </Grid> */}
    </>
  );
};

export default Games;
