import { makeStyles } from "@mui/styles";

export default makeStyles((theme) => ({
  WalletMenu: {
    backdropFilter: "blur(2px)",
    "& .MuiMenu-list": {
      position: "relative",
    },
    "& ul": {
      background: "#24242B",
      boxShadow: "inset 0px 2px 4px rgba(255, 255, 255, 0.02)",
      borderRadius: "12px",
      padding: "20px",
      display: "inline-block",
      width: "100%",
      position: "relative",
      "& li": {
        display: "flex",
        padding: "12px 0 !important",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "16px",
        fontWeight: "500",
        width: "50%",
        float: "left",
        "&:last-child": {
          width: "100%",
          textAlign: "center",
          justifyContent: "center",
          background: "#2B2B38",
          border: "1.4px solid rgba(255, 255, 255, 0.04)",
          boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
          borderRadius: "12px !important",
          marginTop: "18px",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "20px",
          color: "#757786",
          "&:hover": {
            background: "#2B2B38",
            color: "#757786",
          },
        },
        "& span": {
          marginRight: "15px",
          display: "flex",
          "& img": {
            width: "18px",
          },
        },
        "&:hover": {
          color: "#757786",
          backgroundColor: "transparent",
        },
      },
    },
  },
  header: {
    position: "relative",
  },
  headerContent: {
    padding: "20px 60px 20px 20px",
    // minHeight: "80px",
    display: "flex",
    justifyContent: "space-between",
    background: "#24242B",
    position: "fixed",
    top: "0",
    width: "100%",
    zIndex: "9999 ",
    [theme.breakpoints.down("1400")]: {
      padding: "20px",
      zIndex: "9999",
    },
    [theme.breakpoints.down("md")]: {
      padding: "14px 10px",
      zIndex: "99",
      maxHeight: "60px",
    },
    [theme.breakpoints.down("sm")]: {
      zIndex: "9999",
      top: "-1px",
      minHeight: "60px",
    },
  },

  logo: {
    display: "inline-block",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  mobLogo: {
    display: "none",
    [theme.breakpoints.down("sm")]: {
      display: "inline-flex",
      marginLeft: "5px",
    },
  },
  logoName: {
    color: theme.palette.text?.default,
    fontWeight: "600",
    fontSize: "26px",
    lineHeight: "35px",
    paddingLeft: "12px",
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },

  headerActive: {
    [theme.breakpoints.down("md")]: {
      padding: "0",
    },
  },
  headerLeftSection: {
    display: "flex",
    alignItems: "center",
    gap: "60px",
    [theme.breakpoints.down("xl")]: {
      gap: "40px",
    },
    [theme.breakpoints.down("lg")]: {
      gap: "25px",
    },
    [theme.breakpoints.down("lg")]: {
      gap: "0px",
    },
  },

  SearchInput: {
    background: theme.palette.background?.default2,
    boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "12px",
    width: "300px",
    height: "40px",
    [theme.breakpoints.down("xl")]: {
      width: "200px",
    },
    "& .MuiInputBase-input": {
      color: theme.palette.text?.placeholder,
      "&::placeholder": {
        color: "#4D4D5B",
        fontWeight: "400",
        opacity: "1",
        fontSize: "16px",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
      },
    },
    "& .MuiInputBase-formControl": {
      borderRadius: "12px",
      height: "40px",
      "& ::placeholder": {
        color: "#4D4D5B",
        fontWeight: "400",
        opacity: "1",
        fontSize: "16px",
        [theme.breakpoints.down("md")]: {
          fontSize: "14px",
        },
      },
    },
    "& .MuiOutlinedInput-input": {
      paddingLeft: "20px",
    },
    "& fieldset": {
      border: "none",
      height: "40px",
    },
  },

  navigationWrapper: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
    display: "flex",
    alignItems: "center",
    gap: "60px",
    flexGrow: "1",
    [theme.breakpoints.down(1699)]: {
      gap: "30px",
      marginLeft: "16px",
    },
    [theme.breakpoints.down("xl")]: {
      gap: "25px",
      marginLeft: "20px",
    },
    "& ul": {
      display: "flex",
      gap: "60px",
      [theme.breakpoints.down(1699)]: {
        gap: "30px",
      },
      "& li": {
        whiteSpace: "nowrap",
        padding: "0",
        "& a": {
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "16px",
          lineHeight: "23px",
          textTransform: "capitalize",
          color: "#757786",
          textDecoration: "none",
          position: "relative",
          "& span": {
            fontSize: "10px",
            position: "absolute",
            background:
              "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
            color: "#fff",
            top: "-16px",
            right: "-35px",
            display: "inline-block",
            lineHeight: "normal",
            padding: "2px 5px",
            borderRadius: "2px",
          },

          "&:hover": {
            color: theme.palette.text?.default,
          },
          [theme.breakpoints.down("xl")]: {},
          [theme.breakpoints.down("lg")]: {},
        },
      },

      [theme.breakpoints.down("xl")]: {
        gap: "20px",
      },

      [theme.breakpoints.down("lg")]: {
        gap: "20px",
      },

      [theme.breakpoints.down("md")]: {
        gap: "25px",
      },

      [theme.breakpoints.down("sm")]: {
        gap: "25px",
      },

      [theme.breakpoints.down("xs")]: {
        gap: "25px",
      },
    },
  },

  navigation: {
    [theme.breakpoints.down(1200)]: {
      display: "none !important",
    },
  },
  listItemHighlight: {
    color: `${theme.palette.text?.default} !important`,
  },

  sidebarToggleIconClose: {
    left: "-56px",
    textAlign: "center",
    transform: "rotate(-180deg)",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "0",
      left: "0px",
    },
    [theme.breakpoints.down("sm")]: {
      width: "16px",
    },
  },
  sidebarToggleIcon: {
    left: "-56px",
    paddingBottom: "5px",
    textAlign: "center",
    cursor: "pointer",
    [theme.breakpoints.down("md")]: {
      padding: "0!important",
      left: "-5px",
    },
    "& img": {
      [theme.breakpoints.down("lg")]: {
        width: "16px",
      },
    },
  },
  headerLogo: {
    display: "flex",
    textDecoration: "none",
    alignItems: "center",
    color: "#fff",
    "& img": {
      width: "180px",
      [theme.breakpoints.down("lg")]: {
        width: "160px",
      },
      [theme.breakpoints.down("md")]: {
        width: "120px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "34px",
      },
      [theme.breakpoints.down("322")]: {
        width: "30px",
      },
    },
    "& logo": {
      display: "block",
    },
    "& mobLogo": {
      display: "none",
    },
  },
  headerRight: {
    display: "flex",
    gap: "20px",
    [theme.breakpoints.down("md")]: {
      gap: "10px",
      alignItems: "center",
    },
    "& .hamburgerIcon": {
      display: "none",
      "& svg": {
        color: "#6D6F8A",
        width: "20px",
      },
    },
    [theme.breakpoints.down("sm")]: {
      justifyContent: "space-between",
      gap: 0,
    },
  },
  headerLogin: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      gap: "10px",
    },
  },
  headerAfterLogin: {
    display: "flex",
    alignItems: "center",
    gap: "20px",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "270px",
      minWidth: "270px",
      width: "100%",
      justifyContent: "space-between",
      gap: 0,
    },
    [theme.breakpoints.down("xs")]: {
      maxWidth: "240px",
      minWidth: "200px",
      width: "100%",
    },
  },
  headerRightMobCta: {
    [theme.breakpoints.up("md")]: {
      display: "block",
    },
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  afterLoginHeaderRight: {
    [theme.breakpoints.down("md")]: {
      display: "none",
    },
  },
  btnSecondary: {
    background: "#2B2B38 !important",
    border: "1.4px solid rgba(255, 255, 255, 0.04) !important",
    boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02) !important",
    borderRadius: "12px !important",
    padding: "8px 40px !important",
    height: "40px !important",
    maxWidth: "140px !important",
    minWidth: "140px !important",
    width: "100% !important",
    fontSize: "16px !important",
    fontWeight: "500 !important",
    textTransform: "capitalize !important",
    color: `${theme.palette?.text?.default} !important`,
    "&:hover": {
      background: theme.palette?.background?.highlightSocialApp,
    },
    "& img": {
      marginRight: "10px",
      [theme.breakpoints.down("lg")]: {
        marginRight: "5px",
        width: "20px",
      },
    },
    [theme.breakpoints.down("lg")]: {
      padding: "6px 20px",
      fontSize: "14px",
    },

    [theme.breakpoints.down("md")]: {
      padding: "6px 10px !important",
      height: "32px !important",
      borderRadius: "8px !important",
      minWidth: "92px !important",
      fontSize: "14px !important",
    },
  },
  btnPrimary: {
    background: theme.palette?.background?.signUp,
    border: "1.4px solid rgba(106, 113, 199, 0.84)!important",
    boxShadow: "0px 4px 12px 2px rgba(108, 93, 193, 0.24)",
    borderRadius: "8px !important",
    height: "40px",
    minWidth: "140px !important",
    width: "100%",
    padding: "8px 40px !important",
    fontSize: "16px !important",
    color: `${theme?.palette?.text?.btntextcolor} !important`,
    fontWeight: "500",
    textTransform: "capitalize !important",
    [theme.breakpoints.down("lg")]: {
      padding: "6px 20px",
      fontSize: "14px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "6px 10px !important",
      height: "32px",
      borderRadius: "8px !important",
      minWidth: "92px !important",
      fontSize: "14px !important",
    },
  },

  btnNewPrimary: {
    background: theme.palette?.background?.signUp,
    border: "1.4px solid rgba(106, 113, 199, 0.84)!important",
    boxShadow: "0px 4px 12px 2px rgba(108, 93, 193, 0.24)",
    borderRadius: "8px !important",
    height: "40px",
    minWidth: "140px !important",
    width: "100%",
    padding: "8px 40px !important",
    fontSize: "16px !important",
    fontWeight: "500",
    textTransform: "capitalize !important",
    [theme.breakpoints.down("lg")]: {
      padding: "6px 20px",
      fontSize: "14px",
    },
    [theme.breakpoints.down("md")]: {
      padding: "6px 10px !important",
      height: "32px",
      borderRadius: "8px !important",
      minWidth: "92px !important",
      fontSize: "14px !important",
    },
  },

  balanceBtnWrap: {
    width: "230px",
    marginRight: "10px",
    [theme.breakpoints.down("sm")]: {
      // width: "150px",
    },
    [theme.breakpoints.down("322")]: {
      width: "122px",
    },
    "&> span": {
      display: "flex",
      padding: "0 !important",
      alignItems: "center",
      justifyContent: "space-between",
      background: theme.palette?.background?.buttonWallet,
      boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
      borderRadius: "12px 24px 24px 12px !important",
      fontStyle: "normal",
      border: "none !important",
      fontWeight: "500",
      fontSize: "14px !important",
      lineHeight: "23px",
      color: theme.palette?.text?.default,
      whiteSpace: "nowrap",
      gap: "6px",
      // minWidth: "173px !important",
      paddingLeft: "15px !important",
      "&:hover": {
        background: theme.palette?.background?.buttonWallet,
      },
      [theme.breakpoints.down("sm")]: {
        paddingLeft: "5px !important",
        borderRadius: "8px 16px 16px 8px !important",
        height: "35px",
        marginLeft: "0px",
      },
      [theme.breakpoints.down("322")]: {
        height: "28px",
      },
      [theme.breakpoints.down("767")]: {
        gap: "2px",
      },
    },
  },

  coinChangeContent: {
    position: "relative",
  },
  arrowIconWallet: {
    display: "flex",
    width: "180px",
    position: "absolute",
    height: "40px",
    left: "-140px",
    top: "-20px",
    justifyContent: "flex-end",
    alignItems: "center",
    cursor: "pointer",
    [theme.breakpoints.down("sm")]: {
      width: "100px",
      height: "28px",
      left: "-107px",
      top: "-14px",
    },
    "& img": {
      width: "10px",
      height: "6px",
      top: "0px !important",
      left: "-30px",
      [theme.breakpoints.down("sm")]: {
        width: "8px",
        height: "8px",
        left: " 9px !important",
      },
    },
  },
  arrowIcon: {
    display: "none",
    alignItems: "center",
  },
  headerLink: {
    textDecoration: "none",
    color: "#fff",
    textTransform: "uppercase",
    padding: "5px 20px",
    fontWeight: "500",
    "&:hover": {
      color: "#7458DC",
    },
    [theme.breakpoints.down("sm")]: {
      whiteSpace: "nowrap",
    },
  },
  myAccountLink: {
    textDecoration: "none",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "14px",
    lineHeight: "20px",
    color: "#757786",
    display: "flex",
    alignItems: "center",
    gap: "12px",
    [theme.breakpoints.down("sm")]: {
      gap: "8px",
      fontSize: "14px",
    },
    "& img": {
      width: "16px",
    },
  },
  signupWrap: {
    borderRadius: "12px",
    padding: "0 !important",
    // width: "440px",
    width: "440px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
    "& .css-1idn90j-MuiGrid-root": {
      padding: "0",
    },
    "& .MuiGrid-spacing-xs-2": {
      [theme.breakpoints.down("md")]: {
        width: "100%",
        margin: "0",
      },
    },
  },
  loginWrap: {
    borderRadius: "12px",
    width: "440px",
    [theme.breakpoints.down("md")]: {
      width: "100%",
      marginTop: "0",
      marginBottom: "0",
      display: "flex",
      justifyContent: "center",
      alignItems: "center",
    },
  },
  profileWrap: {
    [theme.breakpoints.down("sm")]: {
      "& .MuiPaper-elevation": {},
    },
  },

  walletModal: {
    [theme.breakpoints.down("md")]: {
      justifyContent: "center",
      display: "flex",
      flex: "inherit",
      marginBottom: "0",
    },
  },

  walletPopup: {
    [theme.breakpoints.down("sm")]: {
      position: "relative",
      overflow: "hidden",
      // height: "100vh",
    },
  },

  resetWrap: {
    backgroundColor: "#24242B",
  },

  profileShapIcon: {
    position: "absolute",
    top: "-12px",
    left: "0",
    right: "0",
    margin: "0 auto",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },

  notifyShapeIcon: {
    position: "absolute",
    top: "-12px",
    left: "100px",
    right: "0",
    margin: "0 auto",
    display: "flex",
    justifyContent: "flex-start",
    alignItems: "center",
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
  },
  grayTxt: {
    color: "#757786",
  },
  accountMenu: {
    background: "rgba(23, 23, 28, 0.70)",
    backdropFilter: "blur(2px)",
    zIndex: "999 !important",
    "& .MuiMenu-list": {
      position: "relative",
    },
    "& ul": {
      background: "#24242B!important",
      boxShadow: "inset 0px 2px 4px rgba(255, 255, 255, 0.02)",
      borderRadius: "12px",
      padding: "10px 16px 10px 16px !important",
      display: "inline-block",
      width: "100% !important",
      position: "relative",
      [theme.breakpoints.down("sm")]: {
        padding: "10px 16px 10px 16px !important",
      },

      "& li": {
        display: "flex",
        padding: "0 0 12px!important",
        justifyContent: "flex-start",
        alignItems: "center",
        fontSize: "18px",
        fontWeight: "500",
        width: "50%",
        float: "left",
        [theme.breakpoints.down("sm")]: {
          padding: "0 !important",
          paddingBottom: "15px !important",
          minHeight: "17px",
        },
        "&:last-child": {
          width: "100%",
          textAlign: "center",
          justifyContent: "center",
          background: theme?.palette?.background?.btnbackground,
          border: "1.4px solid rgba(255, 255, 255, 0.04)",
          boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02)",
          borderRadius: "12px !important",
          marginTop: "18px",
          fontStyle: "normal",
          fontWeight: "500",
          fontSize: "16px",
          paddingTop: "12px !important",
          lineHeight: "20px",
          color: theme?.palette?.text?.buttoncolor,
          maxHeight: "40px",
          [theme.breakpoints.down("sm")]: {
            fontSize: "16px",
            minHeight: "32px",
            lineHeight: "0",
            padding: "0 !important",
            borderRadius: "8px !important",
            marginTop: "5px",
            justifyContent: "center!important",
          },
          "&:hover": {
            background: theme?.palette?.background?.btnbackground,
            color: "#757786",
          },
        },
        "& span": {
          marginRight: "15px",
          display: "flex",
          [theme.breakpoints.down("sm")]: {
            marginRight: "8px",
          },
          "& img": {
            width: "18px",
          },
        },
        "&:hover": {
          color: "#757786",
          backgroundColor: "transparent",
        },
      },
    },
  },

  notifyMenu: {
    "& ul": {
      height: "calc(100vh - 120px)",
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 165px)",
      },
    },
  },
  notifyHeight: {
    "& ul": {
      [theme.breakpoints.down("sm")]: {
        height: "calc(100vh - 270px)",
      },
    },
  },
  notificationBodyScroll: {
    overflow: "scroll",
    height: "calc(100vh - 245px)",
  },
  notificationBodyHeight: {
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 380px)",
      overflow: "auto",
      marginTop: "-8px",
    },
  },
  notificationBodyHeightShow: {
    [theme.breakpoints.down("sm")]: {
      height: "calc(100vh - 280px)",
      overflow: "auto",
      marginTop: "-8px",
    },
  },
  headerLeft: {
    display: "flex",
    alignItems: "center",
    marginLeft: "75px",
  },
  headerLeftOpen: {
    display: "flex",
    alignItems: "center",
    marginLeft: "250px",
  },

  sidebarClose: {
    cursor: "pointer",
    position: "absolute",
    right: "-29px",
    top: "0",
    background: "rgb(247 247 248 / 30%)",
    padding: "8px",
    display: "flex",
    borderRadius: "100%",

    "& img": {
      width: "14px",
    },
  },
  balanceIcon: {
    cursor: "pointer",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette?.background?.balanceBg,
    border: "1px solid #6a71c7",
    boxShadow: theme.palette?.background?.balanceBgShadow,
    borderRadius: "24px",
    height: "44px",
    width: "44px",
    position: "relative",
    right: "-1px",
    [theme.breakpoints.down(991)]: {
      height: "35px",
      width: "35px",
      "& img": {
        width: "16px",
      },
    },
    [theme.breakpoints.down(322)]: {
      height: "28px",
      width: "28px",
      "& img": {
        width: "15px",
      },
    },
    "& button": {
      border: "none",
    },
  },
  currencyIcon: {
    display: "inline-flex",
    height: "100%",
    alignItems: "center",
    "& img": {
      width: "20px",
      height: "20px",
      [theme.breakpoints.down("xl")]: {
        padding: "6px 5px",
      },
      [theme.breakpoints.down("sm")]: {
        width: "100%",
      },
    },
  },
  modalUser: {
    alignItems: "center",
  },

  userImage: {
    height: "40px",
    width: "40px",
    position: "relative",
    cursor: "pointer",
    "& svg": {
      stroke: theme.palette.background?.userImgBorder,
    },
    [theme.breakpoints.down("md")]: {
      marginLeft: "8px",
    },
    [theme.breakpoints.down("sm")]: {
      marginLeft: "0",
      height: "48px",
      width: "48px",
    },
    [theme.breakpoints.down("322")]: {
      marginLeft: "0",
      height: "38px",
      width: "38px",
    },
    "& img": {
      width: "100%",
      clipPath:
        "polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%)",
      objectFit: "cover",
      aspectRatio: "1",
      borderRadius: "4px",
      maxHeight: "40px",
      maxWidth: "40px",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      [theme.breakpoints.down("sm")]: {
        height: "43px",
        width: "43px",
        maxWidth: "43px",
        maxHeight: "43px",
      },
      [theme.breakpoints.down("322")]: {
        height: "32px",
        width: "32px",
        maxWidth: "32px",
        maxHeight: "32px",
      },
    },
  },
  userAvatar: {
    height: "60px",
    width: "60px",
    position: "relative",
    cursor: "pointer",

    "& svg": {
      stroke: theme.palette.background?.userImgBorder,
    },
    "& img": {
      width: "100%",
      clipPath:
        "polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%)",
      objectFit: "cover",
      aspectRatio: "1",
      borderRadius: "4px",
      maxHeight: "60px",
      maxWidth: "60px",
      height: "100%",
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    },
    [theme.breakpoints.down("md")]: {
      height: "50px",
      width: "50px",
    },
  },
  userRightImg: {
    "& img": {
      width: "40px",
      display: "block",
      [theme.breakpoints.down("md")]: {
        width: "26px",
      },
    },
  },

  userBorder: {
    width: "100%",
    clipPath:
      "polygon(40% 7.67949%, 43.1596% 6.20615%, 46.52704% 5.30384%, 50% 5%, 53.47296% 5.30384%, 56.8404% 6.20615%, 60% 7.67949%, 81.65064% 20.17949%, 84.50639% 22.17911%, 86.97152% 24.64425%, 88.97114% 27.5%, 90.44449% 30.6596%, 91.34679% 34.02704%, 91.65064% 37.5%, 91.65064% 62.5%, 91.34679% 65.97296%, 90.44449% 69.3404%, 88.97114% 72.5%, 86.97152% 75.35575%, 84.50639% 77.82089%, 81.65064% 79.82051%, 60% 92.32051%, 56.8404% 93.79385%, 53.47296% 94.69616%, 50% 95%, 46.52704% 94.69616%, 43.1596% 93.79385%, 40% 92.32051%, 18.34936% 79.82051%, 15.49361% 77.82089%, 13.02848% 75.35575%, 11.02886% 72.5%, 9.55551% 69.3404%, 8.65321% 65.97296%, 8.34936% 62.5%, 8.34936% 37.5%, 8.65321% 34.02704%, 9.55551% 30.6596%, 11.02886% 27.5%, 13.02848% 24.64425%, 15.49361% 22.17911%, 18.34936% 20.17949%)",
    objectFit: "cover",
    aspectRatio: "1",
    borderRadius: "4px",
    maxHeight: "60px",
    maxWidth: "60px",
    height: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background: theme.palette.background?.userImgBorder,
    borderColor: theme.palette.background?.userImgBorder,
  },

  userProfileLevel: {
    position: "absolute",
    background: theme.palette.background?.chatTagBg,
    border: theme.palette.background?.chatTagBorder,
    boxShadow: theme.palette.background?.chatTagShadow,
    borderRadius: "4px",
    fontStyle: "normal",
    fontWeight: "500",
    fontSize: "12px",
    lineHeight: "1",
    textTransform: "capitalize",
    color: theme.palette.text?.default,
    padding: "0px 9px",
    paddingTop: "2px",
    left: "50%",
    bottom: "-6px",
    width: "100%",
    minWidth: "36px",
    maxWidth: "36px",
    textAlign: "center",
    height: "18px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    transform: "translate(-50%, 0)",
    [theme.breakpoints.down("sm")]: {
      fontSize: "10px",
      lineHeight: "14px",
      bottom: "-4px",
      minWidth: "28px",
      maxWidth: "28px",
      left: "50%",
      height: "13px",
      fontWeight: "600",
      transform: "translateX(-50%)",
      padding: "0px 2px",
      borderRadius: "2px",
      marginLeft: "0px",
    },
  },

  userNameEmail: {
    flexGrow: "1",
    paddingLeft: "18px",

    [theme.breakpoints.down("md")]: {
      paddingLeft: "10px",
    },
    "& p": {
      color: theme?.palette?.text?.txtcolor,
      fontStyle: "normal",
      fontWeight: "500",
      lineHeight: "20px",
      fontSize: "18px",
      margin: "0",
    },
    "& div": {
      color: "#757786",
      fontSize: "14px",
    },
  },
  userEmail: {
    color: theme?.palette?.text?.textcolor,
    [theme.breakpoints.down("xl")]: {
      fontSize: "14px !important",
    },
    [theme.breakpoints.down("sm")]: {
      color: "#757786 !important",
    },
  },

  userIcon: {
    [theme.breakpoints.down("sm")]: {
      width: "26px",
    },
  },

  userName: {
    color: "#FFFFFF",
  },

  linearBar: {
    background: theme?.palette?.background?.default2,
    borderRadius: "4px",
    minHeight: "6px",
    overflow: "visible",
  },

  progressBar: {
    marginTop: "20px",
    marginBottom: "32px",
    [theme.breakpoints.down("sm")]: {
      marginBottom: "15px",
    },
    "& .MuiLinearProgress-barColorPrimary": {
      background:
        "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
      boxShadow: "0px 2px 12px 2px rgba(108, 93, 193, 0.24)",
      borderRadius: "12px",
      width: (props) => `${props.gameProgressWidth}%`,
      transform: "inherit !important",
    },
  },

  grandStep: {
    display: "flex",
    justifyContent: "space-between",
    paddingTop: "9px",
    [theme.breakpoints.down("sm")]: {
      paddingTop: "5px",
    },
    "& p": {
      margin: "0",
      "& span": {
        fontStyle: "normal",
        fontWeight: "500",
        fontSize: "14px",
        lineHeight: "17px",
        [theme.breakpoints.down("sm")]: {
          fontSize: "14px",
        },
        "&:nth-child(1)": {
          color: "#757786",
          paddingRight: "4px",
        },
        "&:nth-child(2)": {
          color: theme?.palette?.text?.txtcolor,
        },
      },
    },
  },
  userSection: {
    display: "flex",
    alignItems: "center",
    position: "relative",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "3px",
    },
    "& .arrowIcon": {
      marginLeft: "60px",
      transform: "rotate(180deg)",
      marginTop: "5px",
      [theme.breakpoints.down("1600")]: {
        marginLeft: "10px",
        marginRight: "2px",
      },
      [theme.breakpoints.down("322")]: {
        marginRight: "10px !important",
      },
    },
    "& userImage": {},
  },
  headerUserName: {
    marginRight: "12px",
    marginLeft: "20px",
    display: "flex",
    flexDirection: "column",
    cursor: "pointer",
    [theme.breakpoints.down("xl")]: {
      marginLeft: "10px",
    },
    [theme.breakpoints.down("lg")]: {
      marginLeft: "30px",
    },
    [theme.breakpoints.down("sm")]: {
      display: "none",
    },
    "& p": {
      margin: "0 0 5px 0",
      fontStyle: "normal",
      fontWeight: "500",
      fontSize: "18px",
      lineHeight: "23px",
      color: theme?.palette?.text?.default,
      textAlign: "right",
    },
  },
  headerProgressBar: {
    width: "120px",
    "& .MuiLinearProgress-barColorPrimary": {
      background:
        "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
      boxShadow: "0px 2px 12px 2px rgba(108, 93, 193, 0.24)",
      borderRadius: "12px",
      width: (props) => `${props.gameProgressWidth}%`,
      transform: "inherit !important",
    },
  },
  headerLinearBar: {
    background: theme?.palette?.background?.default2,
    boxShadow: "inset 0px 2px 8px rgba(0, 0, 0, 0.12)",
    borderRadius: "12px",
    overflow: "visible",
  },

  userProgress: {
    display: "flex",
    flexDirection: "column",
  },

  headerSearchIcon: {
    position: "relative",
    "& img": {
      position: "absolute",
      top: "10px",
      right: "15px",
    },
  },
  backDropbg: {
    background: "rgba(23, 23, 28, 0.70)",
    backdropFilter: " blur(2px)",
    position: "fixed",
    inset: "0",
    fontSize: "0",
    zIndex: "123",
  },
  coinGrid: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: "4px",
    [theme.breakpoints.down("sm")]: {
      gap: "1px",
      fontSize: "14px",
    },
    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: "31px",
      },
      [theme.breakpoints.down("322")]: {
        width: "25px",
      },
    },
  },
  coinContent: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    width: "calc(100% - 43px)",
    "& p": {
      color: "#fff",
    },
  },
  coinContentList: {
    display: "flex",
    alignItems: "center",
    "& img": {
      position: "relative",
      top: "3px",
      left: " -8px",
    },
    "& p": {
      marginLeft: " -5px",
      color: "#fff",
    },
  },
  coinContentListItem: {
    "& .Mui-checked": {
      "& .MuiSvgIcon-root": {
        "& path": {
          fill: "#7b62da",
        },
      },
      "& .MuiSvgIcon-root:last-child": {
        "& path": {
          fill: "#fff",
        },
      },
    },
    "& .MuiFormGroup-root": {
      flexDirection: "row",
      display: "flex",
      gap: "15px",
      alignItems: "center",
    },
    "& .MuiSvgIcon-root": {
      width: "28px",
      height: "28px",
      "& path": {
        fill: "#7C8497",
      },
    },
    "& .MuiFormControlLabel-root ": {
      margin: "0",
      "& .MuiButtonBase-root": {
        padding: "0 !important",
      },
    },
  },
  userNotify: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    width: "44px",
    height: "44px",
    borderRadius: "50%",
    cursor: "pointer",
    background: "#1C1C22",
    [theme.breakpoints.down("sm")]: {
      width: "35px",
      height: "35px",
    },
    [theme.breakpoints.down("322")]: {
      width: "28px",
      height: "28px",
    },
    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: "14px",
      },
    },
  },
  notifyIcon: {
    position: "relative",
    width: "auto",
    height: "20px",
    "& img": {
      width: "20px",
      [theme.breakpoints.down("sm")]: {
        width: "20px",
      },
      [theme.breakpoints.down("322")]: {
        width: "14px",
      },
    },
  },
  notifyCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "radial-gradient(113.34% 127.34% at 50.43% -17.05%, #FFD233 0%, #FF8A00 100%)",
    boxShadow: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.25))",
    borderRadius: "24px",
    height: "15px",
    minWidth: "15px",
    width: "15px",
    fontSize: "8px",
    fontWeight: "600",
    position: "absolute",
    top: "-3px",
    left: "11px",
    [theme.breakpoints.down("sm")]: {
      height: "14px",
      minWidth: "14px",
      width: "14px",
      top: "-5px",
      left: "12px",
    },

    "& span": {
      color: "#fff",
      fontSize: "8px",
      position: "relative",
      left: "0.5px",
    },
  },
  notificationHeader: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    paddingBottom: "8px",
  },
  notificationHeading: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& h3": {
      margin: "0",
      color: "#fff",
      fontSize: "18px",
      fontWeight: "600",
      lineHeight: "normal",
    },
  },
  notificationCount: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    background:
      "radial-gradient(113.34% 127.34% at 50.43% -17.05%, #FFD233 0%, #FF8A00 100%)",
    boxShadow: "drop-shadow(0px 3px 10px rgba(0, 0, 0, 0.25))",
    borderRadius: "24px",
    height: "20px",
    minWidth: "20px",
    width: "20px",
    fontSize: "12px",
    "& span": {
      color: "#fff",
      fontSize: "12px",
      fontWeight: "600",
    },
  },
  closeIconWrap: {
    justifyContent: "center",
    alignItems: "center",
    zIndex: "9",
    display: "flex",
    cursor: "pointer",
    color: " #858DA0",

    "& .MuiSvgIcon-root": {
      fontSize: "20px",
    },
  },
  notificationBody: {
    "& MuiTabs-root": {
      minHeight: "32px !important",
    },
    "& .MuiTabs-flexContainer": {
      minHeight: "32px",
      height: "32px",
      [theme.breakpoints.down("sm")]: {
        minWidth: "60px",
      },
    },
    "& .MuiButtonBase-root": {
      padding: "0 !important",
      color: "#757786",
      fontSize: "16px",
      fontWeight: "500",
      lineHeight: "normal",
      textTransform: "capitalize",
      minWidth: "75px",
      minHeight: "32px",
      height: "32px",
      [theme.breakpoints.down("sm")]: {
        minHeight: "26px",
        height: "26px",
        minWidth: "60px",
        fontSize: "14px",
      },
    },
    "& .Mui-selected": {
      borderRadius: "12px",
      border: "1.4px solid rgba(106, 113, 199, 0.84) !important",
      background:
        "radial-gradient(201.67% 201.67% at 50% -31.67%, #5D69CF 0%, #342A6F 100%)",
      boxShadow: " 0px 4px 12px 2px rgba(108, 93, 193, 0.24)",
      color: "#fff",
      minWidth: "75px",
      minHeight: "32px",
      height: "32px",
      [theme.breakpoints.down("sm")]: {
        minHeight: "26px",
        height: "26px",
      },
    },
    "& .MuiTabs-indicator": {
      display: "none",
    },
  },
  notificationBoxGrid: {
    display: "grid",
    gap: "10px",
  },
  notificationBox: {
    borderRadius: "12px",
    background: "rgba(53, 53, 66, 0.32)",
    boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
    padding: "16px",
    display: "flex",
    alignItems: "start",
    gap: "12px",
  },
  notificationBoxImg: {
    "& img": {
      [theme.breakpoints.down("sm")]: {
        width: "28px",
        height: "28px",
      },
    },
  },
  notificationBoxContent: {
    display: "flex",
    flexDirection: "column",
    gap: "10px",
    width: "100%",
  },
  notificationBoxBody: {
    display: "flex",
    flexDirection: "column",
    gap: "3px",
  },
  notificationBoxHead: {
    display: "flex",
    justifyContent: "space-between",
    "& h4": {
      margin: "0",
      color: "#64C597",
      fontSize: "18px",
      fontWeight: "500",
      lineHeight: "normal",
      [theme.breakpoints.down("sm")]: {
        fontSize: "15px",
        maxWidth: "100%",
      },
    },
    "& p": {
      color: "#757786",
      fontSize: "14px",
      fontWeight: "500",
      lineHeight: "normal",
      margin: "0",
      padding: "2px 0px",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
    },
  },

  notificationFailMsg: {
    color: "#F25C5C !important",
  },
  notificationReferralMsg: {
    color: "#FFC329 !important",
  },
  notificationBoxText: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    "& p": {
      margin: "0",
      width: "100%",
      color: "#fff",
      [theme.breakpoints.down("sm")]: {
        fontSize: "14px",
      },
      "& span": {
        color: "#00DAEA",
        fontSize: "16px",
        fontWeight: "500",
        lineHeight: "normal",
        cursor: "pointer",
      },
    },
  },
  notificationBoxFooter: {
    display: "flex",
    alignItems: "center",
    gap: "10px",
    maxWidth: "180px",
    width: "100%",
  },
  notificationBoxFooterSection: {
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    [theme.breakpoints.down("xs")]: {
      flexWrap: "wrap",
      gap: "5px",
    },
    "& p": {
      margin: "0",
      [theme.breakpoints.down("sm")]: {
        color: "#757786",
        margin: 0,
        fontSize: "12px",
        fontWeight: "500",
        lineHeight: "normal",
        whiteSpace: "nowrap",
      },
    },
  },
  claimBtn: {
    maxWidth: "120px",
    minWidth: "120px !important",
    whiteSpace: "nowrap",
    [theme.breakpoints.down("sm")]: {
      maxWidth: "100px",
      minWidth: "104px !important",
      minHeight: "26px !important",
      height: "26px !important",
      borderRadius: "6px !important",
    },
  },
  dismissBtn: {
    [theme.breakpoints.down("sm")]: {
      borderRadius: "6px !important",
    },
  },

  notificationBoxBtn: {
    minWidth: "80px !important",
    maxWidth: "80px !important",
    color: "#757786 !important",
    fontSize: "16px !important",
    fontWeight: "500 !important",
    lineHeight: "normal !important",
    borderRadius: "8px !important",
    border: "1.4px solid rgba(255, 255, 255, 0.04) !important",
    background: "#2B2B38 !important",
    boxShadow: "0px 2px 12px 0px rgba(255, 255, 255, 0.02) inset",
    [theme.breakpoints.down("sm")]: {
      minWidth: "64px !important",
      maxWidth: "64px !important",
      minHeight: "26px !important",
      height: "26px !important",
      borderRadius: "6px !important",
    },
  },
  notificationBtn: {
    "& button": {
      background: "#2B2B38 !important",
      border: "1.4px solid #FFFFFF0A",
      color: "#757786 !important",
      fontSize: "14px !important",
      fontWeight: "500 !important",
      width: "100%",
      height: "32px !important",
      borderRadius: "8px !important",
    },
  },
  kycVerification: {
    [theme.breakpoints.down("xl")]: {
      alignItems: "center",
    },
  },
  balanceFlexBox: {
    display: "flex",
    alignItems: "center",
    marginLeft: "-8px",
    gap: "18px",
    [theme.breakpoints.down("sm")]: {
      marginLeft: "-10px",
      gap: "14px",
      marginTop: "0px",
    },
    "& img": {
      position: "relative",
      top: "3px",
      // left: "1px",
      cursor: "pointer",
      [theme.breakpoints.down("sm")]: {
        top: "2px",
      },
    },
  },
  walletRadio: {
    width: "28px",
    height: "28px",
    position: "relative",
    "& .Mui-checked.MuiRadio-root": {
      position: "relative",
      width: "28px",
      height: "28px",
      backgroundColor: "transparent",
      backgroundImage: "url('/images/radio-active.svg')!important",
    },
    "& .MuiRadio-root": {
      position: "relative",
      width: "28px",
      height: "28px",
      backgroundColor: "transparent",
      backgroundImage: "url('/images/radio-default.svg')!important",
    },
    "& .MuiRadio-root span": {
      position: "relative",
      width: "28px",
      height: "28px",
      visibility: "hidden",
    },
    btnSecondary: {
      background: theme.palette?.background?.highlightSocialApp,
      border: "1.4px solid rgba(255, 255, 255, 0.04) !important",
      boxShadow: "inset 0px 2px 12px rgba(255, 255, 255, 0.02) !important",
      borderRadius: "12px !important",
      padding: "8px 40px !important",
      height: "40px !important",
      maxWidth: "140px !important",
      minWidth: "140px !important",
      width: "100% !important",
      fontSize: "16px !important",
      fontWeight: "500 !important",
      textTransform: "capitalize !important",
      color: `${theme.palette?.text?.default} !important`,
      "&:hover": {
        background: theme.palette?.background?.highlightSocialApp,
      },
      "& img": {
        marginRight: "10px",
        [theme.breakpoints.down("lg")]: {
          marginRight: "5px",
          width: "20px",
        },
      },
      [theme.breakpoints.down("lg")]: {
        padding: "6px 20px",
        fontSize: "14px",
      },

      [theme.breakpoints.down("md")]: {
        padding: "6px 10px !important",
        height: "32px",
        borderRadius: "8px !important",
        minWidth: "92px !important",
        fontSize: "14px !important",
      },
    },
  },
}));
